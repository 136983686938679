import { forwardRef } from 'react';
import { StyledSelect, StyledOption } from './style';
const Select = forwardRef((props, ref) => {
  return (
    <StyledSelect {...props} ref={ref}>
      {
        props.options && props.options.map(option => (
          <StyledOption
            value={option?.value}
            label={option?.label}
            key={option?.value}
            selected={option?.selected}
          />
        ))
      }
    </StyledSelect>
  );
})

export default Select;
