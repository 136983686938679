import styled from '@emotion/styled'

export default styled.div(props => ({
  background: 'var(--white-color)',
  boxShadow: props.shadow ? '0px 3px 10px var(--box-shadow-color)' : '',
  border: !props.shadow ? '1px solid var(--border-color)' : '',
  padding: 16,
  borderRadius: props.radius,
  marginBottom: 12,
}))
