import React, {
  forwardRef,
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import StyledInput from './style';

const Input = forwardRef((props, ref) => {
  const {
    disabledAutoComplete,
    name,
    onChange,
    value: externalValue,
    defaultValue,
    ...restProps
  } = props;

  const inputRef = useRef(null);
  const combinedRef = ref || inputRef;
  const [internalValue, setInternalValue] = useState(
    defaultValue || externalValue || ''
  );

  useEffect(() => {
    if (disabledAutoComplete && externalValue !== undefined) {
      setInternalValue(externalValue);
    }
  }, [disabledAutoComplete, externalValue]);

  const createSyntheticEventHandler = useCallback(
    (originalHandler, includeValue = false) => {
      return (e) => {
        if (disabledAutoComplete && originalHandler) {
          const syntheticEvent = {
            ...e,
            target: {
              ...e.target,
              name,
              ...(includeValue && { value: e.target.value }),
            },
          };
          originalHandler(syntheticEvent);
        } else if (originalHandler) {
          originalHandler(e);
        }
      };
    },
    [disabledAutoComplete, name]
  );

  const handleChange = useCallback(
    (e) => {
      if (disabledAutoComplete) {
        const newValue = e.target.value;
        setInternalValue(newValue);
      }
      createSyntheticEventHandler(onChange, true)(e);
    },
    [disabledAutoComplete, onChange, createSyntheticEventHandler]
  );

  const getRandomizedInputProps = useMemo(() => {
    const randomName = `field_${Math.random().toString(36).substring(2, 9)}`;

    return {
      ...restProps,
      name: randomName,
      autoComplete: 'off',
      value: internalValue,
      onChange: handleChange,
      ref: combinedRef,
    };
  }, [combinedRef, handleChange, internalValue, restProps]);

  if (!disabledAutoComplete) {
    return <StyledInput {...props} ref={combinedRef} />;
  }

  return (
    <div style={{ position: 'relative' }}>
      <StyledInput {...getRandomizedInputProps} />
      <input
        type="hidden"
        name={name}
        value={internalValue}
        readOnly
        aria-hidden="true"
        tabIndex="-1"
      />
    </div>
  );
});

Input.displayName = 'Input';

export default Input;
